import { useState } from "react";
import { GlobeIcon } from "@components/GlobeIcon";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/Select";
import { setSessionCookie } from "@libs/cookie";

export function StoreSelect({
  defaultStore,
  stores,
}: {
  defaultStore: string;
  stores: {
    label: string;
    value: string;
    url: string;
  }[];
}) {
  const [value, setValue] = useState<string>(defaultStore);

  const onSelect = (value: string) => {
    setValue(value);

    setSessionCookie("store", value);

    const store = stores.find((store) => store.value === value);
    window.open(store?.url, "_self");

    window.plausible("ChangeStore", {
      props: {
        method: "HTTP",
        StoreRegion: value === "us" ? "US" : "Europe",
      },
    });
  };

  return (
    <Select onValueChange={onSelect} value={value}>
      <SelectTrigger className="w-fit">
        <>
          <GlobeIcon className="w-4 h-4" />
          <SelectValue />
        </>
      </SelectTrigger>
      <SelectContent>
        {stores.map((store) => (
          <SelectItem key={store.value} value={store.value}>
            {store.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
